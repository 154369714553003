<template>
    <div>
        <Header
            :type="article.image2 || cat.image2 ? 'large' : 'small'"
            :title="article.name"
            :text="article['text_' + lang]"
            :actions="[]"
            :image="article.image2 || cat.image2"
        >
            <template v-slot:before v-if="cat && cat.id">
                <router-link :to="{ name: 'Assortiment', params: { id: cat.id, name: kebabcase(cat.name) }}" class="button -s mb-r">
                    {{ cat.name }}
                </router-link>
            </template>
        </Header>

        <div class="c w-l l-up:w-xl my-xxl py-xxl flex flex-wrap columns align-center justify-center">
            <div
                v-for="image in ['image', 'image3', 'image4']"
                :key="image"
                v-show="article[image]"
                class="w-12 m-up:w-6 l-up:w-4"
            >
                <img
                    :src="cdnurl(article[image])"
                    class="block w-12 rounded"
                    :alt="article.name"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import { strings } from 'p5-helpers';
import kebabcase from 'lodash.kebabcase';

export default {
    name: 'Product',
    props: {
        id: Number,
    },
    components: {
        Header,
    },
    data() {
        return {
            article: {},
        };
    },
    computed: {
        lang() {
            return this.$i18n.locale;
        },

        data() {
            return this.$store.getters.data;
        },

        cat() {
            if (this.article.category && this.data.products_categories && this.article.category.id) {
                return this.data.products_categories.find((it) => it.id === this.article.category.id) || {};
            }
            return {};
        },
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => vm.getData());
    },
    beforeRouteUpdate(to, from, next) {
        next();
        this.getData();
    },
    methods: {
        kebabcase,
        getData() {
            if (window.PRERENDER_INJECTED) {
                const routedata = window.PRERENDER_INJECTED.routeData[this.$route.path];
                if (routedata) {
                    this.processData(routedata);
                }
            } else {
                const url = strings.replaceBrackets(this.$route.meta.article.endpoint, this.$route.params);
                this.$store.dispatch('get', url).then(this.processData);
            }
        },

        processData(r) {
            this.article = r;
            const lang = this.lang;
            let schemas = [];
            if (this.$route.meta.article.schema) {
                if (typeof this.$route.meta.article.schema === 'function') {
                    schemas = this.$route.meta.article.schema(r, this);
                } else {
                    schemas = this.$route.meta.article.schema;
                }
            }
            setTimeout(() => {
                this.$store.commit('setMeta', {
                    data: {
                        title: r.meta_title,
                        description: r[`text_${lang}`],
                        keywords: (Array.isArray(r.meta_keywords) ? r.meta_keywords : []).map((it) => it.name).join(', '),
                    },
                    schemas,
                });
            }, 10);
        },
    },
};
</script>
